import { ENV } from './environment';

export const ENDPOINTS = {
  //IdpGlobalLogout : ENV.authConfig.idpUrl + '/globalLogout.html?client_id=' + ENV.authConfig.clientId,
  GATEWAY_API : ENV.GATEWAY_BASE_URL +'authorize',
  GET_EVENTTYPES : ENV.GATEWAY_BASE_URL +'eventregistration/v1/eventtypes',
  GET_SUBSCRIBERS : ENV.GATEWAY_BASE_URL +'eventsubscribers/v1/subscribers',
  NOTIFICATION_SEARCH : ENV.GATEWAY_BASE_URL +'eventnotifications/portal/v2/search',
  NOTIFICATION_PAYLOAD : ENV.GATEWAY_BASE_URL +'eventnotifications/notificationmetadata/v1/notifications',
  NOTIFICATION_RETRY : ENV.GATEWAY_BASE_URL +'notifications/v2/retry',
  GET_CONTACTTYPE : ENV.GATEWAY_BASE_URL +'contacts/v1/contacttypes',
  POST_CONTACT : ENV.GATEWAY_BASE_URL +'contacts/v1/contact-list',
  TAG_CONTACT : ENV.GATEWAY_BASE_URL +'contacts/v1/contact',
  UNTAG_CONTACT : ENV.GATEWAY_BASE_URL +'contacts/v1/untag-contact',
  DELETE_CONTACT : ENV.GATEWAY_BASE_URL +'contacts/v1/contact',
  KeepAliveUSMerchantFirm : ENV.keepAliveUrl,
  POST_SUBSCRIBERS: ENV.GATEWAY_BASE_URL +'eventsubscribers/v1/subscribers',
  PATCH_SUBSCRIBERS: ENV.GATEWAY_BASE_URL +'eventsubscribers/v1/subscribers', 
  POST_EVENTS_SUBSCRIBERS: ENV.GATEWAY_BASE_URL +'eventregistration/v1/subscription',
  GET_EVENTS : ENV.GATEWAY_BASE_URL +'eventregistration/v1/subscription/events',
  MERCHANT_HIERARCHY: ENV.GATEWAY_BASE_URL +'eventregistration/v1/merchanthierarchy',
  GET_DRAFT_SUBSCRIBERS: ENV.GATEWAY_BASE_URL +'stagesubscriber/v1/subscribers',
  DELETE_DRAFT_SUBSCRIBERS: ENV.GATEWAY_BASE_URL +'stagesubscriber/v1/subscribers',
  PATCH_DRAFT_SUBSCRIBERS: ENV.GATEWAY_BASE_URL +'stagesubscriber/v1/subscriber',
  POST_DRAFT_SUBSCRIBERS: ENV.GATEWAY_BASE_URL +'stagesubscriber/v1/subscribers',
  CREATE_SUBSCRIBERS: ENV.GATEWAY_BASE_URL +'stagesubscriber/v1/subscribers/create',
  GET_DASHBOARD_EVENT_COUNT: ENV.GATEWAY_BASE_URL +'bendashboard/v1/event/eventCount',
  GET_NOTIFICATIONS_STATUS_COUNT: ENV.GATEWAY_BASE_URL +'bendashboard/v1/notification/statusCount',
  GET_DASHBOARD_SUBS_STATUS_COUNT: ENV.GATEWAY_BASE_URL +'bendashboard/v1/subscriber/statusCountToEventsToSubscribers',
  AWS_NOTIFICATION_RETRY : ENV.GATEWAY_BASE_URL +'eventnotifications/notifications',
  AWS_NOTIFICATION_SEARCH : ENV.GATEWAY_BASE_URL +'eventnotifications/notifications/search',
  POST_SUPPORT : ENV.GATEWAY_BASE_URL + 'notifications/v2/support',
  POST_MERCHANT_HIERARCHY : ENV.GATEWAY_BASE_URL + 'eventregistration/v1/merchanthierarchy',
  AWS_SUBSCRIBER_API : ENV.GATEWAY_BASE_URL +'subscriber-api/v1/subscribers',
  AWS_WEBHOOK_EVENTS : ENV.GATEWAY_BASE_URL + 'subscriber-api/v1/webhook-events',
  AWS_WEBHOOK_EVENT_TYPES : ENV.GATEWAY_BASE_URL + 'subscriber-api/v1/event-types',
  AWS_WEBHOOKS : ENV.GATEWAY_BASE_URL +'subscriber-api/v1/webhooks',
  A4E_EDIT_CONTACTS_WEBHOOK_ID : ENV.GATEWAY_BASE_URL +'subscriber-api/v1/webhook-contacts',
  AWS_EVENTS_COUNT_SUMMARY: ENV.GATEWAY_BASE_URL+'eventnotifications/notifications/generate-event-type-summary',
  A4E_DASHBOARD_SUBS_STATUS_COUNT: ENV.GATEWAY_BASE_URL +'eventnotifications/notifications/generate-summary',
  A4E_NOTIFICATION_STATUS_COUNT: ENV.GATEWAY_BASE_URL+'eventnotifications/notifications/generate-status-summary',
 };