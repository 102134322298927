import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-custom-pagination',
  templateUrl: './custom-pagination.component.html',
  styleUrls: ['./custom-pagination.component.scss'],
})
export class CustomPaginationComponent implements OnInit {

  @Input() pageNumber = 1;
  @Input() isNext: {
    prev: boolean,
    next: boolean,
    first: boolean,
    last: boolean
  };
  @Output() nextPageEvent = new EventEmitter();
  @Output() previousPageEvent = new EventEmitter();
  @Output() fistPageEvent = new EventEmitter();
  @Output() lastPageEvent = new EventEmitter();
  constructor() { }

  ngOnChanges(changes: SimpleChanges): void {
    let isNextChange = changes['isNext']; 
  }

  ngOnInit() { }

  nextPage(value: any): void {
    this.pageNumber = this.pageNumber + 1;
    this.nextPageEvent.emit(this.pageNumber);
  }

  previousPage(value: any) {
    this.previousPageEvent.emit(value);
  }

  firstPage(value: any) {
    this.fistPageEvent.emit(value);
  }
  lastPage(value: any) {
    this.lastPageEvent.emit(value);
  }

}
