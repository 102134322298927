import { Component, Inject, Input, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, FormArray, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { EventService } from "src/app/services/event.service";
import { NgxSpinnerService } from 'ngx-spinner';
import { MessageConstants } from 'src/app/shared/model/MessageConstants';
import { DialogAlertContentComponent} from 'src/app/shared/errorAlert/dialogAlertContent.component';
import { MatDialog, MatDialogConfig } from "@angular/material/dialog";
import { ErrorService } from 'src/app/services/error.service';
import { CustomValidator } from 'src/app/validators/custom.validators';
import { ConfirmationDialog } from "../popup-confirmation/confirmation-dialog";
import { AuthorizedHierarchy, WebhookEventResource } from "../model/webhookEventResource";
import { SharedService } from './../../services/shared.service';

@Component({
  selector: "popup-view-authorized-hierarchy",
  templateUrl: "popup-view-authorized-hierarchy.component.html",
  styleUrls: ["popup-view-authorized-hierarchy.component.scss"],
})

export class PopupViewAuthorizedHierarchy implements OnInit {

  @Input() addAndEditMode = "add";
  @Input() hierarchyDataList: AuthorizedHierarchy[] = [];
  inputHierarchyForm: FormGroup;
  merchantRequest: WebhookEventResource = {} as any;
  webhookRequest: WebhookEventResource = {} as any;
  webhookDataList: AuthorizedHierarchy[] = [];
  enableButtons: boolean = false;
  entityTypes = '';
  items: FormArray;
  showRows = false;
  benUserDetails: any;
  dashBoardRole: any;
  userID: any;
  loading: boolean = true;
  selectedOptions: string[] = [];
  viewOnly:boolean = true;
  hasAccess:boolean = false;
  webhookDetails: any = [];
  tempAuthHierarchy: AuthorizedHierarchy[] = [];
  storedAuthHierarchy: AuthorizedHierarchy[] = [];
  entityTypeDropdown = [
    { name: 'SO - Sales Organization', value:'Sales Organization'},
    { name: 'SA - Sales Channel', value:'Sales Channel'},
    { name: 'NL - National', value:'National'},
    { name: 'PG - Partner Group', value:'Partner Group'},
    { name: 'SC - Super Chain', value:'Super Chain'},
    { name: 'CH - Chain', value:'Chain'},
  ];

  loadingMessages = [
    "No records found",
    "Please wait, loading...",
    "Http status error",
    "Session timed out",
  ];

  constructor(
    private eventService: EventService,
    private spinner: NgxSpinnerService,
    private matDailog: MatDialog,
    public dailogRef: MatDialogRef<PopupViewAuthorizedHierarchy>,
    public fb: FormBuilder,
    private errorService: ErrorService,
    private sharedService: SharedService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {
    const { webhookDetails, viewOnly } = this.data;
    this.webhookDetails = webhookDetails;
    this.hierarchyDataList = webhookDetails.authorizedHierarchies;
    this.viewOnly = viewOnly;
    this.benUserDetails = JSON.parse(localStorage.getItem("userDetails"));
    this.userID = this.benUserDetails.LoginName;
    this.dashBoardRole = this.benUserDetails.APIDashBoardRole;
    if (this.benUserDetails.APIDashBoardRole == "Worldpay Sys Admin" || this.benUserDetails.APIDashBoardRole == "Ops Admin" || this.benUserDetails.APIDashBoardRole == "Company Admin") {
      this.hasAccess = true;
    }
    this.tempAuthHierarchy = [];
    this.webhookDataList = this.hierarchyDataList.map(e => ({
      ...e,
      entityType: this.mapEntityTypeResponse(e.entityType.trim()),
    }));
    this.sortDataByCreatedDate(this.webhookDataList);
    this.storedAuthHierarchy = JSON.parse(JSON.stringify(this.webhookDataList));
    this.createForm();
    this.formArr.valueChanges.subscribe( val => { });
  }

  createForm() {
    const items = [this.initData()];
    this.inputHierarchyForm = this.fb.group({
      items: this.fb.array(items),
    });
    this.items = this.fb.array([]);
  }

  initData(element?:any) {
    return this.fb.group({
      entityType: [element?.entityType || "Sales Organization", Validators.required],
      entityValue: [element?.entityValue || "", Validators.compose([Validators.required, CustomValidator.specialCharValidator])],
    });
  }
  
  get formArr() {
    return this.inputHierarchyForm.get('items') as FormArray;
  }

  // create another field
  onAddRow(element?:any) {
    this.formArr.push(this.initData(element));
  }

  onRemoveRow(rowIndex:number){
    if(this.formArr.length <= 1) {
      this.errorMsgDialog("At least one Hierarchy Detail is required. Kindly add a new Hierarchy Detail before deleting all existing Hierarchy Details.")
    } else {
      this.formArr.removeAt(rowIndex);
    }
  }

  addNewRow(){
    this.onAddRow();
  }

  async saveButton(type?: number):Promise<void> {
    if(this.inputHierarchyForm.invalid && type === 0){
      this.errorMsgDialog("Please enter valid Entity Value");
    } else {
    this.tempAuthHierarchy = this.formArr.value;
    this.tempAuthHierarchy = await Promise.all( this.tempAuthHierarchy.filter(function(el) {
      el.entityValue = el.entityValue.replace(/\s*,\s*/g, ',');
      return el.entityType &&  el.entityValue.trim();
     }));
    const eventId = this.webhookDetails.id;
    this.tempAuthHierarchy = this.mapWebhookDataListRequest().concat(this.tempAuthHierarchy);
    this.webhookRequest.webhookId = this.webhookDetails.webhookId;
    this.webhookRequest.type = this.webhookDetails.type;
    this.webhookRequest.status = this.webhookDetails.status;
    this.webhookRequest.createdBy = this.sharedService.getLoginName();
    if(JSON.stringify(this.webhookDetails.authorizedHierarchies) === JSON.stringify(this.tempAuthHierarchy))
    {
      this.disableEditHierarchy();
    } else {
      this.webhookRequest.authorizedHierarchies = this.tempAuthHierarchy;
      this.spinner.show();
      this.eventService.putWebhookEvents(eventId, this.webhookRequest).subscribe(
        (result) => {
          if (result == null) {
            this.loadingMessages[2] = MessageConstants.INTERNAL_SERVER_ERROR
            this.errorMsgDialog(this.loadingMessages[2]);

          } else if (result.body.authorizedHierarchies.length > 0) {
            this.hierarchyDataList = result.body.authorizedHierarchies;
            this.webhookDetails.authorizedHierarchies = this.hierarchyDataList;
            this.webhookDataList = this.hierarchyDataList.map(e => ({
              ...e,
              entityType: this.mapEntityTypeResponse(e.entityType.trim()),
            }));
            this.sortDataByCreatedDate(this.webhookDataList);
            this.storedAuthHierarchy = JSON.parse(JSON.stringify(this.webhookDataList));
            this.disableEditHierarchy();
            this.createForm();
          }
          this.spinner.hide();
        },
          (error) =>{
            this.spinner.hide();
            if (error.status) {
            this.loadingMessages[2] = this.errorService.gatewayError(error);
              if(this.loadingMessages[2].includes("invalid entityValue")){
                  this.loadingMessages[2] = "One Or More Entity Value is Incorrect";
              }
              this.errorMsgDialog(this.loadingMessages[2]);
              if (this.loadingMessages[2] === MessageConstants.TIME_OUT) {
                window.location.reload();
              }}});
        }
      }
  }

deleteMerchantHierarchy(rowIndex: number) {
  const dialogRef = this.matDailog.open(ConfirmationDialog,{
    data:{
      message: MessageConstants.HIERARCHY_DELETE_ALERT,
      buttonText: {
        ok: 'Yes',
        cancel: 'No'
      }
    }
  });
  dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {
        this.onRemoveRow(rowIndex);
      }
    });
  }

  //Utility methods
  closePopUp() {
    this.dailogRef.close(this.mapWebhookDataListRequest());
  }

  mapWebhookDataListRequest() {
    return this.webhookDataList.map(e => ({
      ...e,
      entityType: this.mapEntityTypeRequest(e.entityType.trim()),
    }));
  }

  deleteHierarchyFromList(index?: number) {
    if(this.webhookDataList.length <= 1) {
      this.errorMsgDialog("At least one Hierarchy Detail is required. Kindly add a new Hierarchy Detail before deleting all existing Hierarchy Details.")
    } else {
      this.webhookDataList.splice(index, 1);
    }
  }

  async onExit(): Promise<void> {
    const result = await this.hasChanges();
    if(result) {
    const dialogRef = this.matDailog.open(ConfirmationDialog,{
      data:{
        message: "There are unsaved changes. Click \"Continue\" to save your changes or \"Exit\" to discard your changes.",
        buttonText: {
          ok: 'Exit',
          cancel: 'Continue'
        }
      }
    });
    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
        if (confirmed) {
          this.webhookDataList = JSON.parse(JSON.stringify(this.storedAuthHierarchy));
          this.closePopUp();
        }
      });
    } else {
      this.closePopUp();
    }
  }

  enableEditHierarchy() {
    this.enableButtons = true;
    (<HTMLInputElement>document.getElementById("editHierarchyButton")).disabled = true;
    (<HTMLInputElement>document.getElementById("editHierarchyButton")).classList.remove("primarybtn");
    (<HTMLInputElement>document.getElementById("editHierarchyButton")).classList.add("disabledButton");
  }

  async hasChanges(): Promise<boolean> {
    this.tempAuthHierarchy = this.formArr.value;
    this.tempAuthHierarchy = await Promise.all( this.tempAuthHierarchy.filter(function(el) {
      el.entityValue = el.entityValue.replace(/\s*,\s*/g, ',');
      return el.entityType &&  el.entityValue.trim();
    }));
    this.tempAuthHierarchy = this.mapWebhookDataListRequest().concat(this.tempAuthHierarchy);
    this.sortDataByCreatedDate(this.webhookDetails.authorizedHierarchies);
    return JSON.stringify(this.webhookDetails.authorizedHierarchies) !== JSON.stringify(this.tempAuthHierarchy);
  }

  async closeButton(): Promise<void> {
      const result = await this.hasChanges();
      result ? this.confirmDiscardChange("There are unsaved changes. Click \"Continue\" to save your changes or \"Exit\" to discard your changes."): this.disableEditHierarchy();
  }

  confirmDiscardChange(msg: string) {
    const dialogRef = this.matDailog.open(ConfirmationDialog,{
      data:{
        message: msg,
        buttonText: {
          ok: 'Exit',
          cancel: 'Continue'
        }
      }
    });
    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
        if (confirmed) {
          this.discardChanges();
        }
      });
  }

  discardChanges(){
    this.spinner.show();
    this.webhookDetails.authorizedHierarchies = JSON.parse(JSON.stringify(this.storedAuthHierarchy)).map(e => ({
      ...e,
      entityType: this.mapEntityTypeRequest(e.entityType.trim()),
    }));;
    this.closePopUp();
    this.openA4EMerchantHierarchy(this.webhookDetails, this.viewOnly);
    this.spinner.hide();
  }

  openA4EMerchantHierarchy(webhookData: any, viewOnlyMode: boolean = false){
    const dialogRef = this.matDailog.open(PopupViewAuthorizedHierarchy, {
      panelClass: 'merchant-hierarchy-dialog-container',
      backdropClass: 'custom-backdrop',
      autoFocus: false,
      closeOnNavigation: true,
      data: {webhookDetails: webhookData, viewOnly: viewOnlyMode},
      disableClose: true,
      hasBackdrop: true});
    dialogRef.afterClosed().subscribe((hierarchyList) => {  
      webhookData.authorizedHierarchies = hierarchyList;
    });
  }

  disableEditHierarchy() {
    this.enableButtons = false;
    (<HTMLInputElement>document.getElementById("editHierarchyButton")).disabled = false;
    (<HTMLInputElement>document.getElementById("editHierarchyButton")).classList.add("primarybtn");
    (<HTMLInputElement>document.getElementById("editHierarchyButton")).classList.remove("disabledButton");
  }

  errorMsgDialog(msg){
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = [{eventErrorMessage: 6,errorMessage: msg}];
    dialogConfig.backdropClass = 'custom-backdrop';
    dialogConfig.hasBackdrop = true;
    dialogConfig.disableClose = true;
    const dialogRef = this.matDailog.open(DialogAlertContentComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(() => {});
  }

  successMsgDialog(msg){
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = [{successMessage: 1,successText: msg}];
    const dialogRef = this.matDailog.open(DialogAlertContentComponent, dialogConfig);
    return dialogRef;
  }

  isEntityValueValid(i) {

    return (<FormArray>this.formArr).controls[i].invalid && 
    (<FormArray>this.formArr).controls[i].value.entityValue != '' &&
    ((<FormArray>this.formArr).controls[i].touched || (<FormArray>this.formArr).controls[i].dirty);
  }

  mapEntityTypeResponse(entityType: string) {
    switch(entityType) {
      case 'Partner Group': return 'PG - Partner Group';
      case 'Sales Organization': return 'SO - Sales Organization';
      case 'Sales Channel': return 'SA - Sales Channel';
      case 'Super Chain': return 'SC - Super Chain';
      case 'National': return 'NL - National';
      case 'Chain': return 'CH - Chain';
      default: return '';
    }
  }

  mapEntityTypeRequest(entityType: string) {
    switch(entityType) {
      case 'PG - Partner Group': return 'Partner Group';
      case 'SO - Sales Organization': return 'Sales Organization';
      case 'SA - Sales Channel': return 'Sales Channel';
      case 'SC - Super Chain': return 'Super Chain';
      case 'NL - National': return 'National';
      case 'CH - Chain': return 'Chain';
      default: return '';
    }
  }
  sortDataByCreatedDate(webhookList: any) {
    webhookList.sort((a, b) => {
      return new Date(b.createdDateTime).getTime() - new Date(a.createdDateTime).getTime();
    });
  }
  ngOnDestroy() {}
  
}
