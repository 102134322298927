import { NgModule,APP_INITIALIZER } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule  } from '@angular/forms';
import { RouteReuseStrategy } from '@angular/router';
import { HttpClientModule,HTTP_INTERCEPTORS }    from '@angular/common/http';
import {NgxPaginationModule} from 'ngx-pagination';
import { IonicModule, IonicRouteStrategy, Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { AuthModule, AUTH_CONFIG, AuthWindowRef,AuthService } from '@ruf/idp-auth';
import { ENV } from '@app/env';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { ComponentsModule } from './modules/components.module';
import { Events } from './services/events';
import { EllipsisPipe } from './shared/pipes/ellipsis.pipe';
import {​​​ BasicGuard }​​​ from'./basic.guard';
import { NgMultiSelectDropDownModule } from 'src/ng-multiselect-dropdown/src/ng-multiselect-dropdown.module';
import { SharedModule } from './shared/shared.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { GlossaryComponent } from './shared/glossary/glossary.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { HighlightSearchPipe } from './shared/pipes/highlight-search.pipe';
import { MatMenuModule } from '@angular/material/menu';
import { MatDialogModule } from '@angular/material/dialog';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button'; 
import { MatSelectModule } from '@angular/material/select';

import { DatePipe, TitleCasePipe } from '@angular/common';
import { ProfilePageComponent } from './modules/profile-page/profile-page.component';
import { DynamicFirm, initApp } from './DynamicFirm';
import { HeaderInterceptor } from './services/http-interceptor';
@NgModule({
    declarations: [AppComponent, EllipsisPipe, GlossaryComponent, HighlightSearchPipe, ProfilePageComponent],
    // RouterModule.forRoot(Routes, {use}),
    imports: [
        NgxSpinnerModule,
        BrowserModule,
        FormsModule,
        IonicModule.forRoot(),
        MatMenuModule,
        MatDialogModule,
        MatButtonModule,
        MatInputModule,
        AppRoutingModule,
        HttpClientModule,
        ReactiveFormsModule,
        NgxPaginationModule,
        AuthModule.forRoot(DynamicFirm.firmChange),
        ComponentsModule,
        NgMultiSelectDropDownModule.forRoot(),
        SharedModule,
        ComponentsModule,
        MatSelectModule,
        BrowserAnimationsModule
    ],
    providers: [
        BasicGuard,
        EllipsisPipe,
        StatusBar,
        SplashScreen,
        Events,
        DatePipe,
        Platform,
        TitleCasePipe,
        { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
        {
            provide: APP_INITIALIZER,
            useFactory: initApp,
            deps: [AuthService],
            multi: true
          },
        { provide: AuthWindowRef, useValue: <IWindow><unknown>window },
        { provide: HTTP_INTERCEPTORS, useClass: HeaderInterceptor, multi: true },
    ],
    bootstrap: [AppComponent]
    // bootstrap: [AppComponent, 
    //   [ɵROUTER_PROVIDERS, {provide:LocationStrategy, useClass:HashLocationStrategy  }]
    // ]
})
export class AppModule {}

interface IWindow extends Window {
  msCrypto: any; //For IE browser
}