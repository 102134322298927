import { Component, OnInit, ViewChild, ElementRef,EventEmitter, Output} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { AlertController } from '@ionic/angular';
import { QueriesService } from 'src/app/services/queries.service';
import { CustomValidator } from 'src/app/validators/custom.validators';
import { DialogAlertContentComponent } from '../errorAlert/dialogAlertContent.component';
import { MessageConstants } from '../model/MessageConstants';
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: 'app-queries',
  templateUrl: './queries.component.html',
  styleUrls: ['./queries.component.scss'],
})
export class QueriesComponent implements OnInit {
  queriesForm: UntypedFormGroup;
  @Output() sendmailEvent = new EventEmitter();
  @ViewChild('fileUploader') fileUploader: ElementRef;

  isfileSizeErrorMessage: boolean= false;
  flagToShowErrMsgAfterSubmitButton: boolean = false;
  _conDetails: any = {
    inquiryType: "",
    email: "",
    contactNumber: "",
    requestorName: "",
    description: "",
    uploadedFile: []
  }

  benDetails: any;
  userID: any;
  userName: any;
  inquiryType: any;
  subCatagory: any;
  description: any;

  inquiryTypeDropdown = [
    { name: 'User Profile', value: 'I have a question about events dashboard', flag: false },
    { name: 'Over view', value: 'I have an event notification specific query', flag: false },
    { name: 'Notifications', value: 'I have an API related technical question', flag: false },
    { name: 'Other', value: 'I have a question related to developer engine', flag: false },
    { name: 'Certificates', value:'I need information pertaining to certification', flag:false},
    { name: 'Testing', value:'I need testing support',flag:false},
    { name: 'Webhook Integration', value:'I need information pertaining to webhook integration',flag:false},
    { name: 'Production Issues',value:'I need assistance on a production issue',flag:false},
  ];

  validation_messages = {
    requestorName: [
      { type: 'required', message: "Requestor Name is required" },
      { type: 'maxLength', message: "Requestor Name will allow upto 50" },
    ],
    description: [
      { type: 'required', message: "Description is required" },
      { type: 'invalidDescription', message: "Only following special characters allowed , ? – ‘ .&" },
    ],
    email: [
      { type: 'required', message: MessageConstants.VALID_MAILID },
    ],
    inquiryType: [
      { type: 'required', message: "Inquiry Type is required" },
    ]
  }
  subcatagoryDropdown: any;

  constructor(
    private fb: UntypedFormBuilder,
    private _queriesService: QueriesService,
    private matDailog: MatDialog,
    private spinner: NgxSpinnerService,
    private _alertController: AlertController,
  ) { }

  ngOnInit() {
    this.setValues();
    this.initializeContactUsForm();
  }
  setValues() {
    this.benDetails = JSON.parse(localStorage.getItem("userDetails"));
    this._conDetails.inquiryType = '';
    this.userID = this.benDetails.LoginName;
    this.userName = this.benDetails.FirstName + " " + this.benDetails.LastName;
    this._conDetails.email = this.benDetails.EmailAddress;
    if (this.benDetails.MobileNumber != null) {
      this._conDetails.contactNumber = this.benDetails.MobileNumber;
    } else {
      this._conDetails.contactNumber = '';
    }
    this._conDetails.requestorName = this.benDetails.FirstName + " " + this.benDetails.LastName;
    this._conDetails.description = '';
  }
  initializeContactUsForm() {
    this.queriesForm = this.fb.group({
      contactNumber: [this._conDetails.contactNumber,[Validators.pattern(/^\+[\d]{6,16}$/)]],
      // /^\+?[\d]{6,16}$/,
      inquiryType: ["", [Validators.required]],
      email: [this._conDetails.email, [Validators.required, CustomValidator.validateEmailField]],
      requestorName: [this._conDetails.requestorName, [Validators.required]],
      description: ["", Validators.compose([Validators.required, CustomValidator.validateDescription])],
      filedata: [this._conDetails.uploadedFile],
    });
  }

  //working one select file
  onFileSelect(event) {
    this._conDetails.uploadedFile = [];
    let currentFileSize = 0;
    let totalFileSize = 0;
    for (var i = 0; i < event.target.files.length; i++) {
      currentFileSize = event.target.files[i].size;
      totalFileSize = totalFileSize + currentFileSize;
      if (totalFileSize < 1048576) {
        this.isfileSizeErrorMessage =false;
        this._conDetails.uploadedFile.push(event.target.files[i]);
      }
    }
    if (totalFileSize > 1048576) {
      this._conDetails.uploadedFile = [];
      this.fileUploader.nativeElement.value = null;
      this.isfileSizeErrorMessage =true;
      totalFileSize = 0;
      
      return;
    }
  }
  sendmail($event) {
    if (this.queriesForm.status === 'INVALID' 
    || this._conDetails.description.trim().length == 0
    || this._conDetails.requestorName.trim().length == 0) {
      this.flagToShowErrMsgAfterSubmitButton = true;
      const dialogConfig = new MatDialogConfig();
      dialogConfig.disableClose = true;
      dialogConfig.data = [{ eventErrorMessage: 0 }];
      const dialogRef = this.matDailog.open(DialogAlertContentComponent, dialogConfig);
      dialogRef.afterClosed().subscribe(result => { });
    } else {
      this.setFormData();
    }
  }

  get formValidator() { return this.queriesForm.controls; }

  resetValues() {

              this.fileUploader.nativeElement.value = null;
              this.isfileSizeErrorMessage =false;
              this._conDetails.uploadedFile=[];
              this.setValues();
              this.initializeContactUsForm();
    }

    async onResetButton() {
        const alert = await this._alertController.create({
          header: 'Confirm Form Reset',
          cssClass: 'mat-dialogue-title',
          message: MessageConstants.FORM_RESET_ALERT,
          buttons: [
            {
              text: 'NO',
              role: 'cancel',
              cssClass: 'secondarybtn'
            },
            {
              text: 'YES',
              cssClass: 'primarybtn',
              handler: () => {
                this.resetValues();
              }
            }
          ],
          backdropDismiss: true,
        });
        await alert.present();
    }

  setFormData() {
    const formData = new FormData();
    formData.append('inquiryType', this._conDetails.inquiryType);
    formData.append('userName', this.userID);
    formData.append('contactNumber', this._conDetails.contactNumber);
    for (var i = 0; i < this._conDetails.uploadedFile.length; i++) {
      formData.append('file', this._conDetails.uploadedFile[i]);
    }
    formData.append('emailAddress', this._conDetails.email);
    formData.append('requestorName', this._conDetails.requestorName.trim());
    formData.append('description', this._conDetails.description.trim());
    this.flagToShowErrMsgAfterSubmitButton = false;
    this.spinner.show();
    this._queriesService.sendMail(formData).subscribe(
      (result) => {
        
        if(result !=null){
          this.spinner.hide();
          this.successMsgDialog(MessageConstants.MAIL_SENT_SUCCESSFULLY).afterClosed().subscribe(result =>{});
          this.resetFileData();
          this.setValues();
          this.initializeContactUsForm();
          this.fileUploader.nativeElement.value = null;
          
      }
      },
      (error) => {
      }
    );

  }
  //Success message dialog
  successMsgDialog(msg) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = [{ successMessage: 1, successText: msg }];
    const dialogRef = this.matDailog.open(
      DialogAlertContentComponent,
      dialogConfig
    );
    return dialogRef;
  }
  resetFileData(){
    this._conDetails.uploadedFile=[];
  }
  
}
