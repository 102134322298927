import { HttpClient,HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { NotfnSearch } from '../shared/model/subscriberInterface';
import { filter } from 'rxjs/operators';
import { Alert, AlertType } from '../shared/alerts/alert.model';
import {ENDPOINTS} from '@app/endpoints';
import { GlobalConstants } from '../shared/model/GlobalConstants';
@Injectable({
    providedIn: 'root'
})
export class SharedService{

    primaryColor: string='green';
    public static idTokenKey: string='';
    public static BenPortalRole: string='';
    public static subscriberInClaims: any = '';
    private _selectedDeal: any = {};

    private _selectedSubscriber: any = {};

    private _selectedSubscriberDetails: any = {};
	
	private _selectedContact :any ={};

    private _subscriberPageType: string;

    private _idToken:any = {};
    private _accessToken:any = {};
    private entityHierarchy:any = [];

    private _firstName:any = "";
    private _lastName:any = "";
    private _loginName:any = "";
    private _emailAddress:any = "";
    private _loginRole:any = "";
    private _profileList:any = "";
    private _benPortalView:any = "";
    private _benPortalRole:any = "";
    private _pageName: String = '';
    private _eventSubscriptionIDAUTHTR: any;
    
    private subject = new Subject<Alert>();
    private defaultId = 'default-alert';

    public static benViewError: boolean=false;
    public static benRoleError: boolean=false;

userName: string;
isUserLoggedIn = false;
sessionWarning = false;
displayTimer = false;
timerInterval: any;
route: string;

constructor(private http: HttpClient) {}

    // enable subscribing to alerts observable
    onAlert(id = this.defaultId): Observable<Alert> {
        return this.subject.asObservable().pipe(filter(x => x && x.id === id));
    }

    // convenience methods
    success(message: string, options?: any) {
        this.alert(new Alert({ ...options, type: AlertType.Success, message }));
    }

    error(message: string, options?: any) {
        this.alert(new Alert({ ...options, type: AlertType.Error, message }));
    }

    info(message: string, options?: any) {
        this.alert(new Alert({ ...options, type: AlertType.Info, message }));
    }

    warn(message: string, options?: any) {
        this.alert(new Alert({ ...options, type: AlertType.Warning, message }));
    }

    // main alert method    
    alert(alert: Alert) {
        alert.id = alert.id || this.defaultId;
        this.subject.next(alert);
    }

    // clear alerts
    clear(id = this.defaultId) {
        this.subject.next(new Alert({ id }));
    }

    public setSubscriber(subscriber: any): void{
        this._selectedSubscriber = subscriber;
    }
    public getSubscriber(): any{
        return this._selectedSubscriber;
    }

    public setSubscriberDetails(subscriberDetails: any): void{
        this._selectedSubscriberDetails = subscriberDetails;
    }
    public getSubscriberDetails(): any{
        return this._selectedSubscriberDetails;
    }
	
	 public setPageType(pageType: any): void{
        this._subscriberPageType = pageType;
    }
    public getPageType(): any{
        return this._subscriberPageType;
    }


    public setContact(contact :any) : void {
        this._selectedContact = contact;
    }

    public getContact() :any {
        return this._selectedContact;
    }

    public setIDToken(token: any): void{
        this._idToken = token;
    }

    public setAccessToken(token: any): void{
        this._accessToken = token;
    }

    public getIDToken(): any{
        return this._idToken;
    }

    public getAccessToken(): any{
        return this._accessToken;
    }

    public setFirstName(firstName: any): void{
        this._firstName = firstName;
    }

    public getFirstName(): any{
        return this._firstName;
    }

    public setLastName(lastName: any): void{
        this._lastName = lastName;
    }

    public getLastName(): any{
        return this._lastName;
    }

    public setLoginName(loginName: any): void{
        this._loginName = loginName;
    }

    public getLoginName(): any{
        return this._loginName;
    }
   
    public setEmailAddress(emailAddress: any): void{
        this._emailAddress = emailAddress;
    }

    public getEmailAddress(): any{
        return this._emailAddress;
    }
    
    public setLoginRole(loginRole: any): void{
        this._loginRole = loginRole;
    }

    public getLoginRole(): any{
        return this._loginRole;
    }

    public setProfileList(profileList: any): void{
        this._profileList = profileList;
    }

    public getProfileList(): any{
        return this._profileList;
    }

    public setBenPortalView(benPortalView: any): void{
        this._benPortalView = benPortalView;
    }

    public setEventSubscriptionIDAUTHTR(eventSubscriptionIDAUTHTR: any): void{
        this._eventSubscriptionIDAUTHTR = eventSubscriptionIDAUTHTR;
    }
    public getEventSubscriptionIDAUTHTR(): any{
        return this._eventSubscriptionIDAUTHTR;
    }
    public getBenPortalView(): any{
        return this._benPortalView;
    }

    public setBenPortalRole(benPortalRole: any): void{
        this._benPortalRole = benPortalRole;
    }

    public getBenPortalRole(): any{
        return this._benPortalRole;
    }
    public setEntityHierarchy(entityHierarchy: any): void{
        this.entityHierarchy = entityHierarchy;
    }

    public getEntityHierarchy(): any{
        return this.entityHierarchy;
    }
    notfnSearch: NotfnSearch=new NotfnSearch();
    httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    };
    
    public getLocalTimeZone(): any{

        var zone = new Date().toString().match(/[\+,\-](\d{4})/g).toString();
        var formattedZone = zone.slice(0,3)+ ':' +zone.slice(3);
        var localSytemDate = new Date(new Date().toString().split('GMT')[0]+' UTC').toISOString().split('Z')[0]+formattedZone;
        return localSytemDate.split('T')[1];
    
    }

    filters = {
        filterStatus: [],
        time: [{ days: 0, value: '' }],
        assignedTo: [],
        originalTeamList: [],
        isTeamEmpty: false,
        customdate: [{ fromDate: '', toDate: '', value: '' }],
        isStatusEmpty: false,
        searchTerm: '',
      };

removeAuthorization(): void {
    localStorage.clear();
    //this.tokenRefreshTimer = null;
    this.userName = null;
    this.isUserLoggedIn = false;
    this.sessionWarning = false;
    this.displayTimer = false;
    clearInterval(this.timerInterval);
  }

 invokeKeepAlive():Observable<any> {
    const url = localStorage.getItem('keepAliveUrl') + '?t=' + Math.floor(Math.random() * Math.floor(9999999));
    return this.http.get(url, {responseType: 'text',withCredentials: true});
  }

  public readGLossarySource(): Observable<any> {
    return this.http.get(GlobalConstants.GLOSSARY_SOURCE, { responseType: "blob" });
  }
/**
 * E5660037 - Start
 * @param pageName 
 */
  public setPageName(pageName: String): void{
    this._pageName = pageName;
  }
  public getPageName(): any{
    return this._pageName;
  }

  public static dateConverterUTC(fromDate:string,toDate:string):any{
    var dateFormatJson;
    const utcDateNow = new Date().toISOString().split('T')[0];
    const utcDateToday = new Date(utcDateNow);
    const systemFromDate = new Date(fromDate);
    const systemToDate = new Date(toDate);

    const utcSysDateDiff =SharedService.daysBetweenDates(utcDateToday,systemToDate);
    const fromAndToDateDiff = Math.abs((SharedService.daysBetweenDates(systemFromDate,systemToDate)));

    const fromDateWith7DayDiff = SharedService.subtractNoOfDays(utcDateToday,fromAndToDateDiff);
          if(utcSysDateDiff<0){
            dateFormatJson = {
              "fromDate": fromAndToDateDiff==6? fromDateWith7DayDiff : utcDateNow,
              "toDate":utcDateNow
            }
          }
          else if(utcSysDateDiff>0){
            dateFormatJson = {
              "fromDate": fromDate,
              "toDate":utcDateNow
            }
          }
          else if(utcSysDateDiff==0){
            dateFormatJson = {
              "fromDate": fromDate,
              "toDate":toDate
            }
          }
    return dateFormatJson;
}  

public static daysBetweenDates(systemDateFrom:Date,systemDateTo:Date):number{
    const diffinMilliSec = systemDateFrom.getTime()-systemDateTo.getTime()
    const daysDifference = Math.floor(diffinMilliSec/(1000*60*60*24))
    return daysDifference;
 }
 public static subtractNoOfDays(utcDate:Date,daydiff:number):string{
   const toDateSubtract= new Date(utcDate);
    toDateSubtract.setDate(toDateSubtract.getDate()-daydiff+1);
    return toDateSubtract.toISOString().split('T')[0]
 }
 
 public static searchDateCompare(localDate:Date):string{
     let month = '' + (localDate.getMonth() + 1);
         let day = '' + localDate.getDate();
         let year = localDate.getFullYear();
     if (month.length < 2) 
         month = '0' + month;
     if (day.length < 2) 
         day = '0' + day;
     return[year, month, day].join('-');
   }	
 public static dateConverterUTCNotifcationSearch(fromDate:string,toDate:string):any{
     var dateFormatJson;
     const utcDateNow = new Date().toISOString().split('T')[0];
     const utcDateToday = new Date(utcDateNow);
     const systemFromDate = new Date(fromDate);
     const systemToDate = new Date(toDate);
     const utcSysDateDiff =SharedService.daysBetweenDates(utcDateToday,systemToDate);
     if(fromDate==toDate ){
         if(utcSysDateDiff==-1){
             dateFormatJson = {
               "fromDate": utcDateNow,
               "toDate":utcDateNow
             }
           }
           else if(utcSysDateDiff==1){
             dateFormatJson = {
               "fromDate": fromDate,
               "toDate":utcDateNow
             }
           }
           else if(utcSysDateDiff==0){
             dateFormatJson = {
               "fromDate": fromDate,
               "toDate":toDate
             }
         }
             else{
                 dateFormatJson = {
                     "fromDate": fromDate,
                     "toDate":toDate
                   } 
             }
     }
     else if(utcSysDateDiff<0){
         dateFormatJson = {
             "fromDate": fromDate,
             "toDate":utcSysDateDiff==-1? utcDateNow : toDate
           }
     }
     else if(utcSysDateDiff==0 ){
         dateFormatJson = {
             "fromDate": fromDate,
             "toDate": toDate
           }
     }
 
     else if( utcSysDateDiff>0){
         dateFormatJson = {
             "fromDate": fromDate,
             "toDate": utcSysDateDiff==1? utcDateNow : toDate
           }
     }
     return dateFormatJson;
 }  
 
 getListOfSubscriberCodesBasedOnVersion(version: any){
    let subscriberCodes = [];
    let subscriberClaims = '';
    let subscriberDataV1;
    if(version == "V1"){
      subscriberDataV1 = JSON.parse(localStorage.getItem("subscriberDataV1"));
      subscriberDataV1.forEach((dt) => {
        if(dt["subscriberCode"]!=undefined)
        subscriberCodes.push(dt["subscriberCode"]);
      });
      
    }
    subscriberClaims = subscriberCodes.toString();
      return subscriberClaims;
}

}