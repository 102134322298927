import { version } from './version';

const GATEWAY_BASE_URL = 'https://cert-dashboard.events.worldpay.com/gateway/';
const CLIENT_BASE_URL = 'https://cert-dashboard.events.worldpay.com/';
const AWS_URL = 'https://cert-eapi.fisglobal.com/';
const authConfig = {  
  'API_UAT': {
    issuer:  'https://login8-uat.fiscloudservices.com/idp/API_UAT/',
    clientId: 'API-Dashboard-API-FIRM-CERT',  
    dummyClientSecret: '09f52f71054b70490d0bc900fc61b6c6999c',
    idpApiKey: 'WPAY_API_KEY',
    redirectUri: 'https://cert-dashboard.events.worldpay.com/idp/callback?fid=API_UAT',
    autoRefresh: false,
    logoutUrl:'https://login8-uat.fiscloudservices.com/idp/API_UAT/globalLogout.html?client_id=API-Dashboard-API-FIRM-CERT',
    waitForTokenInMse: 1000,
    authorizationClaimProperty: 'UserAuthorizations',
    unauthorizedRoute: ['/'],
    listenToToken: 'id_token',
    encrypted:true,    
    interceptUrls:['origin', 'https://dev-eapi.fisglobal.com/gateway/' ,'https://test-eapi.fisglobal.com/gateway', 'https://cert-eapi.fisglobal.com/gateway' ,'https://cert-dashboard.events.worldpay.com/gateway' ,'https://dashboard.events.worldpay.com/gateway']
  },
  'us_worldpay_fis_int-pbint': {
    issuer:  'https://login8-uat.fiscloudservices.com/idp/us_worldpay_fis_int',
    clientId:   'API-Dashboard-USINTERNAL-FIRM-CERT',
    dummyClientSecret: '616f817a009240438009186038d61cb3bdee',
    idpApiKey:  'BenPortalkey',
    redirectUri: 'https://cert-dashboard.events.worldpay.com/idp/callback?fid=us_worldpay_fis_int-pbint',
    autoRefresh: false,
    logoutUrl:'https://login8-uat.fiscloudservices.com/idp/us_worldpay_fis_int/globalLogout.html?client_id=API-Dashboard-USINTERNAL-FIRM-CERT',
    waitForTokenInMse: 1000,
    authorizationClaimProperty: 'UserAuthorizations',
    unauthorizedRoute: ['/'],
    listenToToken: 'id_token',
    encrypted:true,
    interceptUrls:['origin', 'https://dev-eapi.fisglobal.com/gateway/' ,'https://test-eapi.fisglobal.com/gateway', 'https://cert-eapi.fisglobal.com/gateway' ,'https://cert-dashboard.events.worldpay.com/gateway' ,'https://dashboard.events.worldpay.com/gateway']
  }

};

export const ENV = {
  retry: true,
  MAX_TOKEN_SIZE: 128000,
  production: false,
  environment: 'Cert',
  authConfig: authConfig,
  GAVersion: 'UA-151174886-1',
  //PRIVACY_LINK: 'https://www.fisglobal.com/en/merchant-solutions-worldpay/worldpay-privacy-policy',
  VERSION: version,
  SESSION_WARNING_DISPLAY_TIME: 840,
  SESSION_WARNING_EXPIRY_TIME: 60,
  USING_IDP: true,
  GATEWAY_BASE_URL: GATEWAY_BASE_URL,
  CLIENT_BASE_URL: CLIENT_BASE_URL,
  AWS_BASE_URL: AWS_URL,
  //ForgotPasswordLink: 'https://performance.accessmyiq.infovantiv.com/web/guest/forgot-password',
  IDPBaseURL: 'https://login8-uat.fiscloudservices.com/idp',
  logout : 'https://cert-dashboard.events.worldpay.com/',
  IdpGlobalLogout: '',
  keepAliveUrl: "https://login8-uat.fiscloudservices.com/idp/",
  keepAliveSec: 120000,
  pendoApiKey: '524a26a8-5939-4f02-4c54-0bc3c466633b',
  pendoAccountId: 'EventsDashboard-Cert',
  performanceCookiedId: 'optanon-category-C0002'
};
